export default [ 
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
  },
  { 
    title: 'Tarifas',
    route: 'rates',
    icon: 'DollarSignIcon',
  },
  // {
  //   title: 'Orden Trabajo',
  //   route: 'newworkorder',
  //   icon: 'FilePlusIcon',
  // },
  {
    title: 'OT Órdenes de Trabajo', 
    icon: 'ListIcon',
    children: [
      {
        title: 'OT en proceso',
        route: { name: 'workorders', params: { type: 'InProcess' } }    
      },
      {
        title: 'OT Oportunidades',
        route: { name: 'workorders-oportunities', params: { type: 'Oportunity' } }       
      },
      {
        title: 'OT Presupuestados',
        route: { name: 'workorders-budgeted', params: { type: 'Budgeted' } }    

      },
      {
        title: 'OT Completadas',
        route: { name: 'workorders-completed', params: { type: 'Completed' } }       
      },
      {
        title: 'OT Nulos',
        route: 'workorders-nulls', 
      },
    ]
  },
  
]
